import decodeJwt from 'jwt-decode';
import AuthenticationModel from 'src/domain/entity/Authentication';
import AadAuthenticationModel from 'src/domain/entity/AadAuthentication';
import TokenAuthService from 'src/domain/service/TokenAuthService';
import { appConsts } from 'src/utils/consts';

class AuthenticationStore {
  constructor(readonly tokenAuthService: TokenAuthService) {}

  static get isAuthenticated(): boolean {
    if (!abp.session.userId) return false;

    return true;
  }

  async login(model: AuthenticationModel): Promise<void> {
    const result = await this.tokenAuthService.authenticate({
      userNameOrEmailAddress: model.userNameOrEmailAddress,
      password: model.password,
      rememberClient: model.rememberClient,
    });

    const tokenExpireDate = model.rememberClient
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined;
    abp.auth.setToken(result.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      appConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
    );
  }

  async aadLogin(model: AadAuthenticationModel): Promise<void> {
    const decoded = decodeJwt<{ unique_name: string }>(model.accessToken);
    const result = await this.tokenAuthService.aadAuthenticate({
      accessToken: model.accessToken,
      providerName: model.providerName,
      providerKey: decoded.unique_name,
    });

    const tokenExpireDate = new Date(new Date().getTime() + 1000 * result.expireInSeconds);
    abp.auth.setToken(result.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      appConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
    );
  }

  static logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
  }
}
export default AuthenticationStore;
