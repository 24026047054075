import { InputTemplateFile } from 'src/domain/entity/InputTemplateFile';
import { ModelType } from 'src/domain/entity/ModelType';
import { RiverConfig } from 'src/domain/entity/RiverConfig';
import { SanityCheckOutput } from 'src/domain/entity/SanityCheckOutput';
import { Scenario } from 'src/domain/entity/Scenario';
import ScenarioService from 'src/domain/service/ScenarioService';
import { GetByScenarioStateInput } from 'src/domain/service/ScenarioService/dto';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class ScenarioRestService implements ScenarioService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  public async getAll(): Promise<Array<string>> {
    const data = await this.httpAdapter.get<{ result: Array<string> }>(
      'api/services/app/Scenario/GetLastScenarioNames',
      { count: 100 },
    );
    return data.result;
  }

  async create(
    name: string,
    type: ModelType,
    riverSystemName: string,
    description: string,
  ): Promise<Scenario> {
    const body = { name, type, riverSystemName, description };
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/Create',
      body,
    );

    return data.result;
  }

  async reset(scenarioName: string): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/Reset', { name: scenarioName });
  }

  async duplicate(originalName: string, newName: string, description: string): Promise<Scenario> {
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/Duplicate',
      {},
      {
        params: {
          originalName,
          newName,
          description,
        },
      },
    );

    return data.result;
  }

  async delete(scenarioName: string): Promise<void> {
    await this.httpAdapter.delete<{ result: Scenario }>('api/services/app/Scenario/Delete', {
      scenarioName,
    });
  }

  async cancelRun(scenarioName: string): Promise<void> {
    await this.httpAdapter.post<void>(
      'api/services/app/Scenario/Cancel',
      {},
      { params: { name: scenarioName } },
    );
  }

  async getByName(name: string): Promise<Scenario> {
    const data = await this.httpAdapter.get<{ result: Scenario }>(
      'api/services/app/Scenario/GetByName',
      {
        name,
      },
    );

    return data.result;
  }

  async submitSettings(
    name: string,
    startTime: string,
    endTime: string,
    riverSystem: RiverConfig,
  ): Promise<void> {
    const body = { name, startTime, endTime, riverSystem };
    await this.httpAdapter.put<void>('api/services/app/Scenario/SubmitSettings', body);
  }

  async get(id: number): Promise<Scenario> {
    const data = await this.httpAdapter.get<{ result: Scenario }>('api/services/app/Scenario/Get', {
      scenarioId: id,
    });

    return data.result;
  }

  async getInputTemplateFileUrl(scenarioName: string): Promise<InputTemplateFile> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetFiles', {
      name: scenarioName,
    });

    const inputFile = data.result.files['InputFiles/xlsxFile'];
    const templateFile = data.result.files['InputFiles/TemplateFile'];
    const inputFileUrl = inputFile && inputFile[0] ? inputFile[0].accessUrl : null;
    const templateFileUrl = templateFile && templateFile[0] ? templateFile[0].accessUrl : '';

    return {
      hasInputFile: !!inputFileUrl,
      accessUrl: inputFileUrl ?? templateFileUrl,
    };
  }

  async getInputTemplateFileUploadUrl(scenarioName: string): Promise<string> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetInputTemplateFileUploadUrls',
      {
        scenarioName,
      },
    );

    const [file] = data.result?.files ?? [];
    if (!file?.accessUrl) {
      throw new Error('The upload URL is not available.');
    }

    return file.accessUrl;
  }

  async runSanityCheck(scenarioName: string): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/RunSanityCheck', {
      name: scenarioName,
    });
  }

  async runScenario(scenarioName: string): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/RunScenario', {
      name: scenarioName,
    });
  }

  async getFiles(scenarioName: string): Promise<SanityCheckOutput> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetFiles', {
      name: scenarioName,
    });

    const plotFiles = data.result.files['SanityCheckFiles/PlotHtml(SanityCheckPassed)'];
    const warningLogFiles = data.result.files['SanityCheckFiles/WarningLog(SanityCheckPassed)'];
    const errorLocatorFiles = data.result.files['SanityCheckFiles/ErrorLocator(SanityCheckFailed)'];

    const plotUrl = plotFiles ? plotFiles[0].accessUrl : '';
    const successCsvUrl = warningLogFiles ? warningLogFiles[0].accessUrl : '';
    const failCsvUrl = errorLocatorFiles ? errorLocatorFiles[0].accessUrl : '';

    if (plotUrl && successCsvUrl) {
      return {
        csvResult: successCsvUrl,
        plots: plotUrl,
      };
    }

    return {
      csvResult: failCsvUrl ?? '',
      plots: '',
    };
  }

  async scheduleRunScenario(name: string, scheduleStartTime: string | null): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/UpdateScheduledStartTime', {
      name,
      scheduleStartTime,
    });
  }

  async updateEmailNotification(
    scenarioName: string,
    email: string,
    isToSendEmailNotification: boolean,
  ): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/UpdateEmailNotification', {
      scenarioName,
      email,
      isToSendEmailNotification,
    });
  }

  async getByScenarioState(params: GetByScenarioStateInput): Promise<Array<Scenario>> {
    const data = await this.httpAdapter.get<{ result: Array<Scenario> }>(
      'api/services/app/Scenario/GetAll',
      {
        ...params,
        states: params.states.length ? params.states : [params.states],
      },
    );

    return data.result;
  }

  async getRunModelOutputUrl(scenarioName: string): Promise<string> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetFiles', {
      name: scenarioName,
    });

    const zipFile = data.result?.files?.OutputFiles;
    const zipUrl = zipFile ? zipFile[0].accessUrl : '';
    if (!zipUrl) throw new Error(`The zipfile doesn't exist`);

    return zipUrl;
  }
}
