import LocalDatabase from './LocalDatabase';

export const DATABASE_IDS = {
  SCENARIOS: 'SCENARIOS',
  QUEUE: 'QUEUE',
  SCENARIO_TIMEOUT: 'SCENARIO_TIMEOUT',
};

export default class BrowserLocalDatabase implements LocalDatabase {
  private LOCAL_STORAGE_PREFIX = 'GAM';

  constructor(clear?: boolean) {
    if (clear) localStorage.clear();
  }

  save<T>(id: string, data: T): void {
    const gamId = this.buildAppId(id);
    localStorage.setItem(gamId, JSON.stringify(data));
  }

  remove(id: string): void {
    const gamId = this.buildAppId(id);
    localStorage.removeItem(gamId);
  }

  get<T>(id: string): T {
    const gamId = this.buildAppId(id);
    const database = localStorage.getItem(gamId) ?? '{}';
    return JSON.parse(database) as T;
  }

  private buildAppId(id: string) {
    return `${this.LOCAL_STORAGE_PREFIX}_${id}`;
  }
}
