import { makeAutoObservable } from 'mobx';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';
import { FiltersData } from './types';

interface CompletedRun {
  startTime: string | null;
  runId: number | null;
  riverSystem: string;
  scenarioName: string;
  status: string;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING';

export default class CompletedRunsStore {
  _: RootStore;

  riverSystems: Array<River> = [];

  completedRuns: Array<CompletedRun> = [];

  filters: FiltersData = {};

  errorDescription = '';

  currentStep: CurrentStep = 'FETCHING';

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly riverSystemService: RiverSystemService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async fetchAllRiverSystems(): Promise<void> {
    const riverSystems = await this.riverSystemService.getAll();
    this.setRiverSystems(riverSystems);
  }

  async refetchCompletedRuns(): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getCompletedRuns();
  }

  async fetchCompletedRuns(): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getCompletedRuns();
  }

  async getCompletedRuns(): Promise<void> {
    try {
      const completedRuns = await this.scenarioService.getByScenarioState({
        ...this.filters,
        states: this.filters.status ?? ['RunScenarioSuccessful', 'RunScenarioFailed'],
      });
      const formattedCompletedRuns = completedRuns.map((run) => ({
        startTime: run.runStartTime,
        runId: run.databricksScenarioRunId,
        scenarioName: run.name,
        riverSystem: run.riverSystemName,
        status: run.state === 'RunScenarioSuccessful' ? 'Successful' : 'Failed',
      }));
      this.setCompletedRuns(formattedCompletedRuns);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public applyFilters(filters: FiltersData): void {
    this.setFilters(filters);
  }

  private setCompletedRuns(completedRuns: Array<CompletedRun>) {
    this.completedRuns = completedRuns;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }

  private setFilters(filters: FiltersData): void {
    this.filters = filters;
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }

  get currentSnapshot(): string {
    return Object.values(this.filters).join('');
  }
}
