import moment from 'moment';
import ScenarioService from 'src/domain/service/ScenarioService';
import { DATABASE_IDS } from '../database/BrowserLocalDatabase';
import LocalDatabase from '../database/LocalDatabase';
import { BackgroundWorker } from './BackgroundWorker';
import { QueueDB, ScenariosDB } from '../database/types';
import { FAKE_QUEUE, FAKE_SCENARIOS } from '../database/mocks';

const PENDING_PERIOD_TIME = 1000 * 10; // 10 seconds
const ACTIVE_RUNS_PERIOD_TIME = 1000 * 20; // 20 seconds

export default class BrowserBackgroundWorker implements BackgroundWorker {
  constructor(readonly localDatabase: LocalDatabase) {
    this.localDatabase.save(DATABASE_IDS.QUEUE, FAKE_QUEUE);
    this.localDatabase.save(DATABASE_IDS.SCENARIOS, FAKE_SCENARIOS);
  }

  setWorkers(): void {
    this.setPendingWorker();
    this.setActiveRunsWorker();
  }

  private setPendingWorker(): void {
    setInterval(() => {
      const queueById = this.localDatabase.get<QueueDB>(DATABASE_IDS.QUEUE);
      const [firstOnQueue] = Object.values(queueById).sort((a, b) => b.priority - a.priority);
      if (!firstOnQueue) return;
      const { [firstOnQueue.id]: toRemove, ...others } = queueById;
      this.localDatabase.save(DATABASE_IDS.QUEUE, others);

      const newData = this.localDatabase.get<ScenarioService>(DATABASE_IDS.SCENARIOS);
      newData[firstOnQueue.scenarioId].state = 'RunScenarioStarted';
      const nextDatabricksId = Object.values(newData).reduce(
        (acum, next) => acum + (next.databricksScenarioRunId ? 1 : 0),
        0,
      );
      newData[firstOnQueue.scenarioId].databricksScenarioRunId = nextDatabricksId + 1;
      newData[firstOnQueue.scenarioId].runStartTime = moment().format('YYYY-MM-DD HH:mm:ss');
      this.localDatabase.save(DATABASE_IDS.SCENARIOS, newData);
    }, PENDING_PERIOD_TIME);
  }

  private setActiveRunsWorker(): void {
    setInterval(() => {
      const scenariosById = this.localDatabase.get<ScenariosDB>(DATABASE_IDS.SCENARIOS);
      const [firstOnQueue] = Object.values(scenariosById)
        .filter((s) => s.state === 'RunScenarioStarted')
        .sort((a, b) => moment(a.runStartTime).diff(moment(b.runStartTime)));
      if (!firstOnQueue) return;
      scenariosById[firstOnQueue.id].state = 'RunScenarioSuccessful';
      this.localDatabase.save(DATABASE_IDS.SCENARIOS, scenariosById);
    }, ACTIVE_RUNS_PERIOD_TIME);
  }
}
