import { RegionsByCountry } from 'src/domain/entity/RegionsByCountry';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class RiverSystemRestService implements RiverSystemService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  async getCountriesGrouppedByRegion(): Promise<RegionsByCountry> {
    const data = await this.httpAdapter.get<{
      result: Array<{
        countryIso: string;
        regions: Array<string>;
      }>;
    }>('api/services/app/RiverSystem/GetRegionByCountry');

    const regionsByCountry = data.result.reduce(
      (acum, next) => ({
        ...acum,
        [next.countryIso]: next.regions,
      }),
      {},
    );

    return regionsByCountry;
  }

  async getByCountryAndRegion(country: string, region: string): Promise<Array<River>> {
    const data: any = await this.httpAdapter.get('api/services/app/RiverSystem/GetAllFiltered', {
      countryIso: country,
      region,
    });

    return data.result;
  }

  async get(id: string): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/Get', { id });

    return data.result;
  }

  async getAll(): Promise<Array<River>> {
    const data = await this.httpAdapter.get<{
      result: { items: Array<River> };
    }>('api/services/app/RiverSystem/GetAll', { MaxResultCount: 100 });

    return data.result.items;
  }

  async getByName(name: string): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/GetByName', { name });

    return data.result;
  }
}
