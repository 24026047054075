import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import ScenarioService from 'src/domain/service/ScenarioService';
import ScenarioQueueService from 'src/domain/service/ScenarioQueueService';
import RootStore from './rootStore';
import { CurrentStep } from './createScenarioStore';

type LoadingType = 'LOADING' | 'DONE' | 'ERROR';

export default class RunDetailsStore {
  _: RootStore;

  creatorEmailAddress = '';

  notificationEmailAddress = '';

  riverSystemName = '';

  detailsLoading: LoadingType = 'LOADING';

  runModelLoading: LoadingType = 'LOADING';

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly scenarioQueueService: ScenarioQueueService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async init(scenarioName: string): Promise<void> {
    try {
      const scenarioInfo = await this.scenarioService.getByName(scenarioName);
      const nextStep = getCurrentStepFromScenarioState(scenarioInfo.state);
      if (nextStep < CurrentStep.VALIDATION_MODEL_SUCCESS) {
        this._.scenarioDetailsStore.clearScenario();
        throw new Error('This is scenario is not allowed to run a model.');
      }
      this.notificationEmailAddress = scenarioInfo.notificationUserEmail;
      this.creatorEmailAddress = scenarioInfo.creatorUser.emailAddress;
      this.riverSystemName = scenarioInfo.riverSystemName;
      const runModelLoading =
        nextStep === CurrentStep.RUN_MODEL || scenarioInfo.scheduleStartTime ? 'DONE' : 'LOADING';
      this.setRunModelLoading(runModelLoading);
      this.setDetailsLoading('DONE');
    } catch (err) {
      this.setDetailsLoading('ERROR');
      throw err;
    }
  }

  async runModel(
    scenarioName: string,
    email: string,
    isToSendEmailNotification: boolean,
    startTime?: string,
  ): Promise<void> {
    try {
      await this.scenarioService.updateEmailNotification(
        scenarioName,
        email,
        isToSendEmailNotification,
      );

      if (startTime) {
        await this.scenarioService.scheduleRunScenario(scenarioName, startTime);
      } else {
        await this.scenarioService.runScenario(scenarioName);
      }

      this.setRunModelLoading('DONE');
    } catch (err) {
      this.setRunModelLoading('ERROR');
      throw err;
    }
  }

  async editModel(scenarioName: string): Promise<void> {
    await this.scenarioService.reset(scenarioName);
  }

  async getOutputUrl(scenarioName: string): Promise<string> {
    const url = await this.scenarioService.getRunModelOutputUrl(scenarioName);
    return url;
  }

  async deleteRun(scenarioName: string): Promise<void> {
    await this.scenarioService.delete(scenarioName);
  }

  public hasModelAlreadyRan(): boolean {
    return this.runModelLoading === 'DONE';
  }

  private setDetailsLoading(detailsLoading: LoadingType): void {
    this.detailsLoading = detailsLoading;
  }

  private setRunModelLoading(runModelLoading: LoadingType): void {
    this.runModelLoading = runModelLoading;
  }

  public setNotificationEmailAddress(notificationEmailAddress: string): void {
    this.notificationEmailAddress = notificationEmailAddress;
  }
}
