import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import BlobService from 'src/domain/service/BlobService';
import ScenarioService from 'src/domain/service/ScenarioService';
import { InputTemplateFile } from 'src/domain/entity/InputTemplateFile';
import { CurrentStep } from './createScenarioStore';
import RootStore from './rootStore';

export default class ValidationModelStore {
  _: RootStore;

  templateFile: InputTemplateFile = { hasInputFile: false, accessUrl: '' };

  plotsUrl = '';

  csvUrl = '';

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly blobService: BlobService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  public async getTemplateFileUrl(scenarioName: string): Promise<void> {
    const data = await this.scenarioService.getInputTemplateFileUrl(scenarioName);
    this.setTemplateFile(data);
  }

  public async updateTemplateFile(file: File, scenarioName: string): Promise<void> {
    const uploadUrl = await this.scenarioService.getInputTemplateFileUploadUrl(scenarioName);
    await this.blobService.updateTemplateFile(uploadUrl, file);
  }

  public async init(scenarioName: string): Promise<void> {
    this._.createScenarioStore.setCurrentStep(CurrentStep.VALIDATION_MODEL_LOADING);
    const nextStep = await this._.scenarioDetailsStore.loadScenarioByName(scenarioName);
    if (
      nextStep !== CurrentStep.TEMPLATE_CREATED &&
      nextStep !== CurrentStep.VALIDATION_MODEL_SUBMITTING &&
      nextStep !== CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL &&
      nextStep !== CurrentStep.VALIDATION_MODEL_FAIL &&
      nextStep !== CurrentStep.VALIDATION_MODEL_SUCCESS &&
      nextStep !== CurrentStep.RUN_MODEL
    ) {
      this._.scenarioDetailsStore.clearScenario();
      throw new Error('This is scenario is not allowed to upload a new file.');
    }

    await this.getTemplateFileUrl(scenarioName);
    this._.createScenarioStore.setCurrentStep(nextStep);
    if (this._.createScenarioStore.isSanityCheckResultsReady()) {
      await this.fetchFileResults(scenarioName);
    }
  }

  public async runSanityCheck(scenarioName: string): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.VALIDATION_MODEL_SUBMITTING);
      await this.scenarioService.runSanityCheck(scenarioName);
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.VALIDATION_MODEL);
      throw err;
    }
  }

  public async checkValidationModelStatus(scenarioId: number): Promise<boolean> {
    const data = await this.scenarioService.get(scenarioId);
    const currentStep = getCurrentStepFromScenarioState(data.state);
    const isDone =
      currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS ||
      currentStep === CurrentStep.VALIDATION_MODEL_FAIL ||
      currentStep === CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL;
    this._.createScenarioStore.setCurrentStep(currentStep);
    return isDone;
  }

  public async fetchFileResults(scenarioName: string): Promise<void> {
    const files = await this.scenarioService.getFiles(scenarioName);
    this.plotsUrl = files.plots;
    this.csvUrl = files.csvResult;
  }

  public setTemplateFile(templateFile: InputTemplateFile): void {
    this.templateFile = templateFile;
  }
}
