import { makeAutoObservable } from 'mobx';
import { ModelType } from 'src/domain/entity/ModelType';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import RootStore from 'src/stores/rootStore';
import { CurrentStep } from './createScenarioStore';

interface Scenario {
  id: number;
  name: string;
  description: string;
  riverSystemName: string;
}

const scenarioInitData = { id: -1, name: '', description: '', riverSystemName: '' };

export default class ScenarioDetailsStore {
  _: RootStore;

  scenarios: Array<string> = [];

  modelType: ModelType = 'Validation';

  country = '';

  region = '';

  riverSystem: River = { id: '', name: '', stations: [] };

  scenario = scenarioInitData;

  constructor(
    rootStore: RootStore,
    readonly riverSystemService: RiverSystemService,
    readonly scenarioService: ScenarioService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async createScenario(name: string, description: string): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      const data = await this.scenarioService.create(
        name,
        this.modelType,
        this.riverSystem.name,
        description,
      );
      this.setScenario(data);
    } finally {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SCENARIO_CREATED);
    }
  }

  async duplicateScenario(
    originalName: string,
    newName: string,
    description: string,
  ): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      const data = await this.scenarioService.duplicate(originalName, newName, description);
      this.setScenario(data);
    } finally {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SCENARIO_CREATED);
    }
  }

  async deleteScenario(name: string): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      await this.scenarioService.delete(name);
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
      throw err;
    }
  }

  async loadScenarioByName(name: string): Promise<CurrentStep> {
    const data = await this.scenarioService.getByName(name);
    this.setScenario(data);
    await this._.scenarioDetailsStore.loadRiverByName();
    return getCurrentStepFromScenarioState(data.state);
  }

  async loadRiverByName(): Promise<void> {
    const data = await this.riverSystemService.getByName(this.scenario.riverSystemName);
    this.setRiverSystem(data);
  }

  public clearScenario(): void {
    this.setScenario(scenarioInitData);
  }

  public defineScenarioInfo(country: string, region: string, riverSystem: River): void {
    this.country = country;
    this.region = region;
    this.riverSystem = riverSystem;
    this._.createScenarioStore.setCurrentStep(CurrentStep.RIVER_SELECTED);
  }

  async fetchScenarios(): Promise<void> {
    const scenarios = await this.scenarioService.getAll();
    this.setScenarios(scenarios);
  }

  public setScenarios(scenarios: Array<string>): void {
    this.scenarios = scenarios;
  }

  public setScenario(scenario: Scenario): void {
    this.scenario = scenario;
  }

  public setRiverSystem(river: River): void {
    this.riverSystem = river;
  }

  public defineModelType(modelType: ModelType): void {
    this.modelType = modelType;
    this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
  }

  public isLoadedScenarioEqual(scenarioName: string): boolean {
    return this.scenario.name === scenarioName;
  }
}
