import { ScenariosDB } from './types';

export const FAKE_SCENARIOS: ScenariosDB = {
  '1': {
    id: 1,
    name: 'marcos-1',
    riverSystemName: 'SaintMarys',
    description: 'test',
    state: 'RunScenarioPending',
    creatorUser: { emailAddress: 'test@test.com' },
    notificationUserEmail: 'notification@test.com',
    isToSendEmailNotification: false,
    databricksScenarioRunId: null,
    scheduleStartTime: null,
    runStartTime: '2023-04-05T14:27:18.055Z',
  },
  '2': {
    id: 2,
    name: 'marcos-2',
    riverSystemName: 'SaintMarys',
    description: 'test',
    state: 'RunScenarioPending',
    creatorUser: { emailAddress: 'test@test.com' },
    notificationUserEmail: 'notification@test.com',
    isToSendEmailNotification: false,
    databricksScenarioRunId: null,
    scheduleStartTime: null,
    runStartTime: '2023-04-05T14:27:18.055Z',
  },
  '3': {
    id: 3,
    name: 'marcos-3',
    riverSystemName: 'SaintMarys',
    description: 'test',
    state: 'RunScenarioPending',
    creatorUser: { emailAddress: 'test@test.com' },
    notificationUserEmail: 'notification@test.com',
    isToSendEmailNotification: false,
    databricksScenarioRunId: null,
    scheduleStartTime: null,
    runStartTime: null,
  },
  '4': {
    id: 4,
    name: 'marcos-4',
    riverSystemName: 'SaintMarys',
    description: 'test',
    state: 'RunScenarioPending',
    creatorUser: { emailAddress: 'test@test.com' },
    notificationUserEmail: 'notification@test.com',
    isToSendEmailNotification: false,
    databricksScenarioRunId: null,
    scheduleStartTime: null,
    runStartTime: null,
  },
};

export const FAKE_QUEUE = {
  '1': { id: 1, scenarioId: 1, scenarioName: 'marcos-1', priority: 30, version: '1' },
  '2': { id: 2, scenarioId: 2, scenarioName: 'marcos-2', priority: 40, version: '1' },
  '3': { id: 3, scenarioId: 3, scenarioName: 'marcos-3', priority: 10, version: '1' },
  '4': { id: 4, scenarioId: 4, scenarioName: 'marcos-4', priority: 20, version: '1' },
};
