import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import { CurrentStep } from './createScenarioStore';

export default class TemplateConfigurationStore {
  _: RootStore;

  stations: Array<string> = [];

  includeStorage: Record<string, boolean> = {};

  isEnabled: Record<string, boolean> = {};

  generators: Record<string, Array<string>> = {};

  constructor(
    rootStore: RootStore,
    readonly riverSystemService: RiverSystemService,
    readonly scenarioService: ScenarioService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  public async init(scenarioName: string): Promise<void> {
    if (this._.scenarioDetailsStore.isLoadedScenarioEqual(scenarioName)) {
      const { currentStep } = this._.createScenarioStore;
      this._.createScenarioStore.setCurrentStep(CurrentStep.LOADING_TEMPLATE);
      await this.fetchRiverTemplate(this._.scenarioDetailsStore.riverSystem.id);
      this._.createScenarioStore.setCurrentStep(currentStep);
      return;
    }
    this._.createScenarioStore.setCurrentStep(CurrentStep.LOADING_TEMPLATE);
    const nextStep = await this._.scenarioDetailsStore.loadScenarioByName(scenarioName);
    if (nextStep !== CurrentStep.SCENARIO_CREATED) {
      this._.scenarioDetailsStore.clearScenario();
      throw new Error('This is scenario is not allowed to create a template configuration.');
    }
    await this.fetchRiverTemplate(this._.scenarioDetailsStore.riverSystem.id);
    this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_TEMPLATE);
  }

  private async fetchRiverTemplate(riverSystemId: string): Promise<void> {
    const res = await this.riverSystemService.get(riverSystemId);

    const stations: Array<string> = [];
    const generators: Record<string, Array<string>> = {};
    res.stations.forEach((station) => {
      stations.push(station.name);
      this.isEnabled[station.name] = true;
      this.includeStorage[station.name] = station.includeStorage;
      station.generators.forEach((generator) => {
        this.isEnabled[`${station.name}${generator.name}`] = true;
        generators[station.name] = generators[station.name] ?? [];
        generators[station.name].push(generator.name);
      });
    });

    this.generators = generators;
    this.setStations(stations);
  }

  public addStation(station: string, includeStorage: boolean): void {
    this.stations = this.stations.concat(station);
    this.generators[station] = [];
    this.isEnabled[station] = true;
    this.includeStorage[station] = includeStorage;
  }

  public addGenerator(station: string, generator: string): void {
    this.generators[station] = this.generators[station].concat(generator);
    this.isEnabled[`${station}${generator}`] = true;
  }

  public setGeneratorsByStation(station: string, generators: Array<string>): void {
    this.generators[station] = generators;
  }

  public changeIsEnabled(station: string, generator: string, enabled: boolean): void {
    this.isEnabled[`${station}${generator}`] = enabled;
  }

  public changeIncludeStorage(station: string, includeStorage: boolean): void {
    this.includeStorage[station] = includeStorage;
  }

  public async submitTemplate(
    scenarioName: string,
    riverSystemName: string,
    startTime: string,
    endTime: string,
  ): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_TEMPLATE);
      await this.scenarioService.submitSettings(scenarioName, startTime, endTime, {
        name: riverSystemName,
        stations: this.stations
          .filter((generatorName) => this.isEnabled[generatorName])
          .map((stationName) => ({
            name: stationName,
            includeStorage: this.includeStorage[stationName],
            generators: this.generators[stationName]
              .filter((generatorName) => this.isEnabled[`${stationName}${generatorName}`])
              .map((generatorName) => ({
                name: generatorName,
                isIncluded: true,
              })),
          })),
      });
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.TEMPLATE_CREATED);
      throw err;
    }
  }

  public async checkSubmitStatus(scenarioId: number): Promise<boolean> {
    const data = await this.scenarioService.get(scenarioId);
    const currentStep = getCurrentStepFromScenarioState(data.state);
    this._.createScenarioStore.setCurrentStep(currentStep);
    return (
      currentStep === CurrentStep.TEMPLATE_CREATED || currentStep === CurrentStep.SCENARIO_CREATED
    );
  }

  public setStations(stations: Array<string>): void {
    this.stations = stations;
  }
}
