import { makeAutoObservable } from 'mobx';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';

interface ScheduledRun {
  scheduleStartTime: string | null;
  scenarioName: string;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING' | 'SUBMITTING';

export default class ScheduledRunsStore {
  _: RootStore;

  currentStep: CurrentStep = 'FETCHING';

  errorDescription = '';

  scheduledRuns: Array<ScheduledRun> = [];

  constructor(rootStore: RootStore, readonly scenarioService: ScenarioService) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async fetchScheduledRuns(): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getScheduledRuns();
  }

  async refetchScheduledRuns(): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getScheduledRuns();
  }

  private async getScheduledRuns(): Promise<void> {
    try {
      const schedules = await this.scenarioService.getByScenarioState({
        states: 'SanityCheckSuccessful',
        hasScheduleTime: true,
      });
      const mappedScheduledRuns = schedules.map((run) => ({
        scenarioName: run.name,
        scheduleStartTime: run.scheduleStartTime,
      }));
      this.setScheduledRuns(mappedScheduledRuns);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async scheduleScenario(scenarioName: string, scheduleStartTime: string): Promise<void> {
    try {
      this.setCurrentStep('SUBMITTING');
      await this.scenarioService.scheduleRunScenario(scenarioName, scheduleStartTime);
      const newScheduledRuns = this.scheduledRuns.filter((s) => s.scenarioName !== scenarioName);
      this.setScheduledRuns([...newScheduledRuns, { scenarioName, scheduleStartTime }]);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async runScenario(scenarioName: string): Promise<void> {
    try {
      this.setCurrentStep('SUBMITTING');
      await this.scenarioService.runScenario(scenarioName);
      const newScheduledRuns = this.scheduledRuns.filter((s) => s.scenarioName !== scenarioName);
      this.setScheduledRuns(newScheduledRuns);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async cancelScheduledRun(scenarioName: string): Promise<void> {
    try {
      this.setCurrentStep('SUBMITTING');
      await this.scenarioService.scheduleRunScenario(scenarioName, null);
      const newScheduledRuns = this.scheduledRuns.filter((s) => s.scenarioName !== scenarioName);
      this.setScheduledRuns(newScheduledRuns);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public setScheduledRuns(scheduledRuns: Array<ScheduledRun>): void {
    this.scheduledRuns = scheduledRuns;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }

  get isSubmitting(): boolean {
    return this.currentStep === 'SUBMITTING';
  }
}
