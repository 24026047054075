import { HomeOutlined } from '@ant-design/icons';
import LoadableComponent from '../Loadable/index';

const BASE_APP_ROUTE = '/app';
const BASE_USER_ROUTE = '';

export const ROUTES = {
  BASE_USER_ROUTE,
  LOGIN: `${BASE_USER_ROUTE}/login`,
  BASE_APP_ROUTE,
  HOME: `${BASE_APP_ROUTE}/home`,
  QUEUE: `${BASE_APP_ROUTE}/queue`,
  CREATE_NEW_SCENARIO: `${BASE_APP_ROUTE}/create-new-scenario`,
  SCENARIO_DETAILS: `${BASE_APP_ROUTE}/scenario-details`,
  SCENARIO_DETAILS_FROM_PREVIOUS: (scenarioName?: string): string => {
    const url = `${BASE_APP_ROUTE}/scenario-details-from-previous/:scenarioName`;
    return scenarioName ? url.replace(':scenarioName', scenarioName) : url;
  },
  COMPLETED_RUNS: `${BASE_APP_ROUTE}/completed-runs`,
  TEMPLATE_CONFIGURATION: (scenarioName?: string): string => {
    const url = `${BASE_APP_ROUTE}/template-configuration/:scenarioName`;
    return scenarioName ? url.replace(':scenarioName', scenarioName) : url;
  },
  TEMPLATE_CREATING: `${BASE_APP_ROUTE}/template-creating`,
  VALIDATION_MODEL: (scenarioName?: string): string => {
    const url = `${BASE_APP_ROUTE}/validation-model/:scenarioName`;
    return scenarioName ? url.replace(':scenarioName', scenarioName) : url;
  },
  VALIDATION_MODEL_PLOTS: (scenarioName?: string): string => {
    const url = `${BASE_APP_ROUTE}/validation-model-plots/:scenarioName`;
    return scenarioName ? url.replace(':scenarioName', scenarioName) : url;
  },
  RUN_DETAILS: (scenarioName?: string): string => {
    const url = `${BASE_APP_ROUTE}/run-details/:scenarioName`;
    return scenarioName ? url.replace(':scenarioName', scenarioName) : url;
  },
};

export const userRouter: any = [
  {
    path: ROUTES.BASE_USER_ROUTE,
    exact: true,
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('src/components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('src/scenes/LoginAad')),
    showInMenu: false,
  },
];

export const appRouters: any = [
  {
    path: ROUTES.BASE_APP_ROUTE,
    exact: true,
    name: 'root',
    permission: '',
    title: 'Root',
    component: LoadableComponent(() => import('src/components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: ROUTES.HOME,
    name: 'home',
    permission: '',
    title: 'Home',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/Home')),
  },
  {
    path: ROUTES.CREATE_NEW_SCENARIO,
    name: 'create-new-scenario',
    permission: '',
    title: 'CreateNewScenario',
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/CreateNewScenario')),
  },
  {
    path: ROUTES.QUEUE,
    name: 'queue',
    permission: '',
    title: 'Queue',
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/Queue')),
  },
  {
    path: ROUTES.COMPLETED_RUNS,
    name: 'completedRuns',
    permission: '',
    title: 'CompletedRuns',
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/CompletedRuns')),
  },
  {
    path: ROUTES.SCENARIO_DETAILS,
    name: 'scenario-details',
    permission: '',
    title: 'ScenarioDetails',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/ScenarioDetails')),
  },
  {
    path: ROUTES.SCENARIO_DETAILS_FROM_PREVIOUS(),
    name: 'scenario-details-from-previous',
    permission: '',
    title: 'ScenarioDetailsFromPrevious',
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/ScenarioDetailsFromPrevious')),
  },
  {
    path: ROUTES.TEMPLATE_CONFIGURATION(),
    name: 'template-configuration',
    permission: '',
    title: 'TemplateConfiguration',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/TemplateConfiguration')),
  },
  {
    path: ROUTES.TEMPLATE_CREATING,
    name: 'template-creating',
    permission: '',
    title: 'TemplateCreating',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/TemplateCreating')),
  },
  {
    path: ROUTES.VALIDATION_MODEL(),
    name: 'validation-model',
    permission: '',
    title: 'ValidationModel',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/ValidationModel')),
  },
  {
    path: ROUTES.VALIDATION_MODEL_PLOTS(),
    name: 'validation-model-plots',
    permission: '',
    title: 'ValidationModelPlots',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/ValidationModelPlots')),
  },
  {
    path: ROUTES.RUN_DETAILS(),
    name: 'run-details',
    permission: '',
    title: 'RunDetails',
    icon: HomeOutlined,
    showInMenu: false,
    component: LoadableComponent(() => import('src/scenes/RunDetails')),
  },
];

export const routers = [...userRouter, ...appRouters];
